/** 
 * @Desc: 个人中心--我的宝宝
 */
<template>
  <div class="my-baby-wrapper card-wrapper">
    <!-- <img src="../../assets/new_ui/personal_ip.png" alt="" class="img-bottom-bg"> -->
    <img src="../../assets/new_ui/personal_ip.png" alt="" class="img-bottom-bg">
    <div
      v-if="!loading"
      class="add-baby"
      :class="babyList.length ? 'add-baby-right-top' : 'add-baby-center'"
      title="添加宝宝"
      @click="_addEditBaby()"
    >
      <div v-if="!babyList.length" class="add-baby-inner">
        <!-- <i class="iconfont icontianjia"></i> -->
      </div>
      <p v-if="!babyList.length" class="p-add">添加宝宝</p>
      <!-- <i v-else class="iconfont icontianjia"></i> -->
    </div>
    <!-- <img src="../../assets/personal_ip.png" alt="" class="img-bottom-bg"> -->
    <img
      src="../../assets/new_ui/personal_ip_left.png"
      alt=""
      class="img-bottom-bg"
      style="left: -0.5rem"
    >
    <div
      :class="{
        'baby-list': babyList.length,
        'baby-list-1': babyList.length === 1,
        'baby-list-2': babyList.length === 2,
        'baby-list-n': babyList.length > 2,
      }"
    >
      <div
        :class="{
          'baby-item': true,
          'baby-item-1': item.studentGender === 1,
          'baby-item-2': item.studentGender === 2
        }"
        v-for="(item, index) in babyList"
        :key="index"
        :name="index"
      >
        <img
          v-if="item.studentGender === 1"
          src="../../assets/new_ui/baby-card-1.png"
          alt=""
          class="img-top-bg"
        >
        <img v-else src="../../assets/new_ui/baby-card-2.png" alt="" class="img-top-bg">
        <div class="baby-item-box">
          <p class="baby-name no-wrap">{{item.studentName}}</p>
          <p class="baby-age no-wrap">{{item.studentBirthday | age}}</p>
          <p class="baby-org no-wrap">{{item.orgName}}</p>
          <p class="baby-class no-wrap">{{item.className}}</p>
          <!-- <i class="iconfont iconbianji baby-edit" @click="_addEditBaby(item)"></i> -->
          <img
            class="baby-edit"
            v-if="item.studentGender === 1"
            src="../../assets/new_ui/nan_edit.png"
            @click="_addEditBaby(item)"
            alt=""
          >
          <img
            v-if="item.studentGender === 2"
            class="baby-edit"
            src="../../assets/new_ui/nv_edit.png"
            @click="_addEditBaby(item)"
            alt=""
          >
        </div>
      </div>
    </div>
    <!-- 添加宝宝 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="566px"
      :append-to-body="true"
      :close-on-click-modal="false"
      :before-close="_dialogClose"
      custom-class="self-el-dialog add-baby-dialog"
    >
      <p class="dialog-title">{{editItem ? '编辑宝宝' : '添加宝宝'}}</p>
      <el-form
        label-position="right"
        :model="infoForm"
        :rules="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="self-el-form"
      >
        <el-form-item label="姓名：" prop="studentName">
          <el-input
            ref="studentName"
            v-model.trim="infoForm.studentName"
            placeholder="请输入姓名"
            :maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="studentGender">
          <el-select v-model="infoForm.studentGender" placeholder="请选择性别">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生日：" prop="studentBirthday" class="form-item-studentBirthday">
          <el-date-picker
            v-model="infoForm.studentBirthday"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="请选择宝宝生日"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="我是孩子的：" prop="relationType">
          <el-select v-model="infoForm.relationType" placeholder="请选择关系">
            <el-option
              v-for="(item, index) in relationshipList"
              :key="index"
              :label="item.relationshipName"
              :value="item.relationshipId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="bottom-btns">
        <el-button
          type="primary"
          size="small"
          :loading="okLoading"
          @click="!okLoading && _save()"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { debounce, formatDateTime } from "utils/util";
import { reqGetBabyList, reqUpdateBaby } from "api/personal-center";
export default {
  data() {
    const babyNameR = (rule, val, callback) => {
      if (this.okLoading && !val) {
        return callback(new Error("请输入姓名"));
      } else if (!val) {
        return callback();
      }
      if (val.length > 10 || val.length < 2) {
        return callback(new Error("姓名仅支持2-10个字符"));
      }
      callback();
    };
    const genderR = (rule, val, callback) => {
      if (this.okLoading && !val) {
        return callback(new Error("请选择性别"));
      }
      if (!val) {
        return callback();
      }
      callback();
    };
    const birthdayR = (rule, val, callback) => {
      if (this.okLoading && !val) {
        return callback(new Error("请选择宝宝生日"));
      }
      if (!val) {
        return callback();
      }
      callback();
    };
    const relationshipR = (rule, val, callback) => {
      if (this.okLoading && !val) {
        return callback(new Error("请选择与宝宝的关系"));
      }
      if (!val) {
        return callback();
      }
      callback();
    };
    return {
      loading: true,
      dialogVisible: false,
      babyList: [],
      okLoading: false,
      infoForm: {
        studentName: "",
        studentGender: null,
        studentBirthday: "",
        relationType: null
      },
      ruleForm: {
        studentName: [{ trigger: "blur", validator: babyNameR }],
        studentGender: [{ trigger: "change", validator: genderR }],
        studentBirthday: [{ trigger: "change", validator: birthdayR }],
        relationType: [{ trigger: "change", validator: relationshipR }]
      },
      relationshipList: [
        {
          relationshipId: 1,
          relationshipName: "爸爸"
        },
        {
          relationshipId: 2,
          relationshipName: "妈妈"
        },
        {
          relationshipId: 3,
          relationshipName: "爷爷"
        },
        {
          relationshipId: 4,
          relationshipName: "奶奶"
        },
        {
          relationshipId: 5,
          relationshipName: "姥爷"
        },
        {
          relationshipId: 6,
          relationshipName: "姥姥"
        },
        {
          relationshipId: 7,
          relationshipName: "保姆"
        },
        {
          relationshipId: 8,
          relationshipName: "其他"
        }
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      editItem: null
    };
  },
  methods: {
    _addEditBaby(item) {
      this.editItem = item;
      this.infoForm = Object.assign({}, this.infoForm, item);
      this.dialogVisible = true;
      if (!item) {
        this.$nextTick(() => {
          this.$refs.studentName.focus();
        });
      }
    },
    _dialogClose(type) {
      this.dialogVisible = false;
      this.editItem = null;
      this.$refs.ruleForm.resetFields();
      this.infoForm = {
        studentName: "",
        studentGender: null,
        studentBirthday: "",
        relationType: null
      };
    },
    _save: debounce(function() {
      this.okLoading = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this._babyHttp();
        } else {
          this.okLoading = false;
        }
      });
    }),
    /**
     * 更新基本资料
     * **/
    _babyHttp() {
      let obj = {
        studentName: this.infoForm.studentName,
        studentGender: this.infoForm.studentGender,
        studentBirthday:
          this.infoForm.studentBirthday.toString().indexOf("-") === -1
            ? formatDateTime(
                new Date(this.infoForm.studentBirthday),
                "yyyy-MM-dd"
              )
            : this.infoForm.studentBirthday,
        relationType: this.infoForm.relationType
      };
      if (this.editItem) {
        obj.studentId = this.editItem.studentId;
      }
      return reqUpdateBaby(obj, this.editItem ? "edit" : "add")
        .then(res => {
          this.okLoading = false;
          if (res.code === 0) {
            if (this.editItem) {
              let i = this.babyList.findIndex(
                ele => ele.studentId === this.editItem.studentId
              );
              this.babyList[i] = Object.assign({}, this.infoForm);
              this.babyList = [...this.babyList];
            } else {
              this._getData();
            }
            this._dialogClose();
          } else {
            this.$message({
              type: "error",
              message: this.editItem ? "宝宝信息修改失败" : "添加宝宝失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          this.okLoading = false;
          console.log("添加宝宝失败", err);
        });
    },

    /**
     * 数据获取
     * **/
    _getData() {
      let obj = {};
      return reqGetBabyList(obj)
        .then(res => {
          if (res.code === 0) {
            this.babyList = res.data;
            for (let item of this.babyList) {
            }
          } else {
            this.$message({
              type: "error",
              message: "宝宝信息失败",
              duration: 2000
            });
          }
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log("宝宝信息获取失败", err);
        });
    }
  },
  created() {
    this._getData();
  }
};
</script>
<style lang="less">
.my-baby-wrapper {
  // .page-wrapper {
  //   width: 1300px;
  // }
  // overflow: hidden;
  padding: 0.7rem 0.15rem 0.5rem 2rem;
  padding: 0.7rem 0.3rem 0.3rem 2rem;
  .add-baby {
    position: absolute;
    z-index: 10;
    cursor: pointer;

    .icontianjia {
      color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-30%, -50%);
    }
    &.add-baby-center {
      position: relative;
      text-align: center;
      background: #fff;
      width: 258px;
      height: 328px;
      border: 5px solid #e3facd;
      top: 50%;
      left: 50%;
      // transform: translate(-50%, -50%);
      transform: translate(-83%, -56%);
      border-radius: 30px;
      .add-baby-inner {
        width: 84px;
        height: 84px;
        // background: #ffce75;
        background: url("../../assets/new_ui/add_baby.png") no-repeat;
        // border-radius: 12px;
        // border: 4px solid #ffffff;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -70%);

        // .icontianjia {
        //   font-size: 30px;
        // }

        &:hover {
          // width: 92px;
          // height: 98px;
          // background: #f3bb55;
          // opacity: 0.8;
          background: url("../../assets/new_ui/add_baby_hover.png") no-repeat;
          background-size: 100% 100%;
          // position: relative;
          // top: 50%;
          // left: 50%;
          // transform: translate(-48%, -65%);
        }
      }
      .p-add {
        height: 16px;
        font-size: 16px;
        line-height: 16px;
        color: #46851b;
        margin-top: 120px;
      }
    }
    &.add-baby-right-top {
      width: 0.7rem;
      height: 0.7rem;
      // background: #ffce75;
      background: url("../../assets/new_ui/add_baby.png") no-repeat;
      background-size: 100% 100%;

      // border-radius: 12px;
      // border: 4px solid #ffffff;
      bottom: 0.75rem;
      right: 0.66rem;

      &:hover {
        // background: #f3bb55;
        // opacity: 0.8;
        background: url("../../assets/new_ui/add_baby_hover.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  ::-webkit-scrollbar {
    display: block !important;
  }
  .baby-list {
    width: 100%;
    height: 100%;
    overflow: auto;
    // padding: 0.82rem 2.14rem;
    // padding-right: 1rem;
    padding-top: 0.3rem;
    padding-left: 0.1rem;
    margin-bottom: 0;
    padding-right: 0;
    // overflow: hidden;
    &:hover {
      // overflow: scroll;
    }

    &.baby-list-1 {
      // padding-top: 1.2rem;
      // padding-left: 2.3rem;
      padding-top: 1.28rem;
      padding-left: 1.65rem;
    }
    &.baby-list-2 {
      // padding-top: 100px;
      // padding-left: 235px;
      // padding: 50px;
      // margin: auto;
      // text-align: center;
      // padding: 100px;
      // padding: 1rem 2.1rem 0 2.1rem;
      margin-top: 0.1rem;
      .baby-item {
        // margin-right: 38px;
        margin-right: 0.5rem;
      }
    }
    &.baby-list-n {
      // margin-top: 40px;
      // padding-top: 40px;
      // padding-left: 90px;
      // margin-bottom: 40px;
      height: calc(100% - 26px);
      .baby-item {
        margin-right: 0.5rem;
      }
    }
    .baby-item {
      // width: 218px;
      // height: 216px;
      // padding: 80px 20px 0;
      padding: 1.31rem 0px 0;
      // background: #fff9ee;
      border-radius: 30px;
      position: relative;
      display: inline-block;
      margin-bottom: 0.58rem;
      &.baby-item-1 {
        .baby-name,
        .baby-org {
          color: #54a3b4;
        }
        .baby-age,
        .baby-class,
        .baby-edit {
          color: #7abcc9;
        }
      }
      &.baby-item-2 {
        .baby-name,
        .baby-org {
          color: #ff4940;
        }
        .baby-age,
        .baby-class,
        .baby-edit {
          color: #f98f89;
        }
      }
      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }
      .baby-item-box {
        // width: 190px;
        // height: 140px;
        width: 2.58rem;
        height: 1.74rem;
        text-align: center;
        background: url("../../assets/new_ui/baby_box.png") no-repeat 100% 100%;
        position: relative;
        background-size: 100%;
        padding: 0 0.2rem;
        padding-top: 28px;
        overflow: hidden;
      }
      .img-top-bg {
        width: 2.358rem;
        height: 2.19rem;
        border-radius: 20px 20px 0 0;
        position: absolute;
        top: -23px;
        left: 8px;
        left: 0.11rem;
      }
      .baby-edit {
        width: 0.62rem;
        height: 0.64rem;
        display: none;
        position: absolute;
        right: 0.03rem;
        bottom: 0.06rem;

        // right: 3px;
        // bottom: 6px;
        font-size: 20px;
        z-index: 10;

        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .baby-name {
        // font-size: 16px;
        font-size: 18px;
        height: 20px;
        // line-height: 16px;
        margin-top: 10px;

        font-size: 0.18rem;
        height: 0.2rem;
        margin-top: 0.1rem;
      }
      .baby-age {
        // font-size: 14px;
        font-size: 16px;
        height: 14px;
        // line-height: 14px;
        margin-top: 0.1rem;

        font-size: 0.16rem;
        height: 0.14rem;
        margin-top: 0.1rem;
      }
      .baby-org {
        font-size: 16px;
        height: 16px;
        // line-height: 16px;
        margin-top: 0.2rem;

        font-size: 0.16rem;
        height: 0.16rem;
        margin-top: 0.1rem;
      }
      .baby-class {
        font-size: 0.16rem;
        height: 0.2rem;
        margin-top: 0.1rem;
        position: relative;
        z-index: 5;
      }
      &:hover {
        .baby-edit {
          display: inline-block;
        }
      }
    }
  }
}

.add-baby-dialog {
  .el-form {
    .form-item-studentBirthday {
      .el-date-editor {
        width: 280px;
        .el-input__prefix {
          display: none;
        }
        .el-input__suffix {
          display: inline-block;
          width: 20px;
          height: 18px;
          background-image: url("../../assets/new_ui/icon-calendar.png");
          background-repeat: no-repeat;
          background-size: 20px 18px;
          position: absolute;
          right: 0.12rem;
          top: 12px;
          .el-input__suffix-inner {
            display: none;
          }
        }
      }
    }
  }
  .bottom-btns {
    padding-left: 100px;
    margin-bottom: 20px;
    .el-button {
      width: 280px;
    }
  }
  .self-el-form {
    margin-left: 5px;
  }
}
</style>