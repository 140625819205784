/** 
 * @Desc: 个人中心--最近播放
 */
<template>
  <div class="history-wrapper card-wrapper" v-loading="loading">
    <div v-if="dataList.length" id="resourceContent" class="resource-content">
      <ResourceItem
        v-for="(item, index) in dataList"
        :key="index"
        :item="item"
        component-type="history"
      />
      <div v-if="scrollLoading" class="scroll-loading">
        <img class="img-scroll-loading loading-animation" src="../../assets/loading.png" alt="">
      </div>
    </div>
    <!-- <div v-if="!loading && !dataList.length" class="no-data-box">
      <img src="../../assets/new_ui/personal_ip.png" alt="" class="img-bottom-bg">
      <img
        src="../../assets/new_ui/personal_ip_left.png"
        alt=""
        class="img-bottom-bg"
        style="left: -50px"
      >
      <div class="no-data-content">
        <img src="../../assets/new_ui/no_data_img.png" alt="" class="img-no-data">
        <div class="no-data-text">
          <p class="no-data-title">最近播放里空空如也～</p>
          <br>
          <p class="no-data-title">快去看看视频吧</p>
        </div>
      </div>
    </div>-->

    <div v-if="!loading && !dataList.length" class="no-data-box">
      <!-- <img src="../../assets/personal_ip.png" alt="" class="img-bottom-bg"> -->
      <img src="../../assets/new_ui/personal_ip.png" alt="" class="img-bottom-bg">
      <img
        src="../../assets/new_ui/personal_ip_left.png"
        alt=""
        class="img-bottom-bg"
        style="left: -0.5rem"
      >
      <div class="no-data-content">
        <img src="../../assets/new_ui/no_data_img.png" alt="" class="img-no-data">
        <div class="no-data-text">
          <p class="no-data-title">最近观看里空空如也～</p>
          <br>
          <p class="no-data-title">快去看看视频吧</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ResourceItem from "components/resource-item";
import { reqGetHistoryPlay } from "api/resource";
import {
  localStorageUtil,
  debounce,
  throttle,
  GetPageType,
  GetRandomNum,
  formatDateTime
} from "utils/util";
export default {
  inject: ["reload"],
  components: {
    ResourceItem
  },
  data() {
    return {
      loading: true,
      dataList: [],
      scrollLoading: false,
      pageTotal: 0,
      pageSize: 12,
      pageNum: 1
    };
  },
  watch: {
    syncHistory: {
      immediate: true,
      handler(val) {
        if (val) {
          this._getData();
        }
      }
    }
  },
  computed: {
    ...mapState(["accountInfo", "isLogin", "syncHistory"])
  },
  methods: {
    /**
     * 页面重置
     * **/
    _resetPage() {
      this.loading = true;
      this.dataList = [];
      this.scrollLoading = false;
      this.pageNum = 1;
      $("#app")[0].scrollIntoView({ behavior: "instant" });
    },
    /**
     * 数据获取
     * **/
    _getData() {
      if (!this.isLogin) {
        let localHistory = localStorageUtil.getItem("localHistory");
        if (!localHistory) {
          localHistory = [];
        }
        this.pageTotal = localHistory.length;
        this._operateData(localHistory);
        this.loading = false;
        this.scrollLoading = false;
        return;
      }
      let obj = {};
      if (this.pageNum === 1) {
        this.loading = true;
      }
      return reqGetHistoryPlay(obj)
        .then(res => {
          this.loading = false;
          this.scrollLoading = false;
          if (res.code === 0) {
            this.pageTotal = res.data.length;
            this._operateData(res.data);
          } else {
            this.$message({
              type: "error",
              message: "资源获取失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          this.loading = false;
          this.scrollLoading = false;
          console.log("资源获取失败", err);
        });
    },

    _operateData(data) {
      let _list = [...data];
      for (let item of _list) {
        item.randomNum = GetRandomNum(1, 10);
        item.randomNum = item.randomNum * 50;
        item.pageType = item.typeId;
        let _now = formatDateTime(new Date(), "yyyy-MM-dd HH:mm");
        let _time = formatDateTime(
          new Date(item.playedTime),
          "yyyy-MM-dd HH:mm"
        );
        _now = _now.split("-");
        _time = _time.split("-");
        if (_now[0] === _time[0]) {
          item.playedTime1 = formatDateTime(
            new Date(item.playedTime),
            "MM-dd HH:mm"
          );
        } else {
          item.playedTime1 = formatDateTime(
            new Date(item.playedTime),
            "yyyy-MM-dd HH:mm"
          );
        }
      }
      this.dataList = _list;
      setTimeout(() => {
        let ele_list = $("#resourceContent .resource-item-inner").each(
          function() {
            $(this).removeClass("wobblyBouncy");
          }
        );
      }, 1000);
    },
    /**
     * 监听scroll事件
     * **/
    _handleWinScroll(e) {
      if (
        this.loading ||
        this.scrollLoading ||
        (this.dataList.length && this.dataList.length >= this.pageTotal)
      ) {
        return;
      }
      var nScrollHight = 0;
      var nScrollTop = 0;
      let body_h = $("body").height();
      let doc_h = $(document).height();
      let scroll_t = $(document).scrollTop(); //滚动到的当前位置
      // console.log('???????????', doc_h - body_h + scroll_t);
      if (doc_h - body_h - scroll_t < 20) {
        this.scrollLoading = true;
        this.pageNum++;
        this._getData();
        console.log("is end");
      }
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      $("#app")[0].scrollIntoView({ behavior: "instant" });
      window.addEventListener("scroll", this._handleWinScroll);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this._handleWinScroll);
  }
};
</script>
<style lang="less">
.history-wrapper {
  display: inline-block;
  width: 100%;
  // min-height: calc(100vh - 270px) !important;
  padding: 0.35rem;

  display: flex;
  justify-content: center;
  .desc-box {
    // top: 0.2rem;
    .img-desc {
      right: 0.2rem;
      top: 0.2rem;
    }
  }
  .desc-tip {
    width: 2.32rem !important;
    height: 1.74rem !important;
    top: 0.1rem !important;
    left: 0.1rem !important;
    // overflow: auto !important;
    padding: 0.15rem 0.14rem !important;

    .img-close {
      right: 0.15rem !important;
      top: 0.12rem !important;
    }
  }
  .desc-tip::-webkit-scrollbar {
    display: none !important;
  }
  .resource-content {
    position: relative;
    padding-left: 0.4rem !important;
    padding-top: 0.05rem !important;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 0 auto;

    .scroll-loading {
      width: 100%;
      height: 50px;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: -50px;
      .img-scroll-loading {
        width: 50px;
        height: 50px;
      }
    }
    .desc-content {
      font-size: 0.12rem !important;
      width: 105% !important;
      height: 96% !important;
      line-height: 0.17rem !important;
      padding-top: 0.02rem;
      margin-top: 0.2rem !important;
    }
  }
  ::-webkit-scrollbar {
    display: block !important;
  }
  // ::-webkit-scrollbar {
  //   display: none !important;
  // }

  .no-data-box {
    // margin-top: 185px;
  }
  .no-data-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-no-data {
      // width: 100px;
      // flex: 1;
      margin-top: 0px;
      margin-right: 50px;
      width: 190px;
      height: 160px;
    }
  }

  .resource-item-inner {
    .el-image__inner {
      width: 100% !important;
      height: 100% !important;
    }
    .resource-count {
      // width: 74px;
      // height: 20px;
      // line-height: 20px;
      // font-size: 12px;

      width: 0.74rem;
      height: 0.2rem;
      line-height: 0.2rem;
      font-size: 0.12rem;
      bottom: 0.5rem;
    }
  }
}
.resource-name {
  font-size: 0.15rem !important;
}
</style>