/** 
 * @Desc: 个人中心
 */
<template>
  <div class="basic-info-wrapper card-wrapper">
    <img src="../../assets/new_ui/personal_ip.png" alt="" class="img-bottom-bg">
    <img
      src="../../assets/new_ui/personal_ip_left.png"
      alt=""
      class="img-bottom-bg"
      style="left: -0.5rem;"
    >
    <el-form
      label-position="right"
      :model="infoForm"
      :rules="ruleForm"
      ref="ruleForm"
      label-width="1rem"
      class="self-el-form"
    >
      <el-form-item label="手机号：" prop="phone">
        <el-input
          v-model.trim="infoForm.phone"
          placeholder="请输入手机号（平台登录账号）"
          ref="accountPhone"
          :maxlength="11"
          v-enterNumber
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称：" prop="nickname">
        <el-input v-model.trim="infoForm.nickname" placeholder="请输入昵称" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="姓名：" prop="parentName">
        <el-input v-model.trim="infoForm.parentName" placeholder="请输入姓名" :maxlength="10"></el-input>
      </el-form-item>
      <el-form-item label="性别：" prop="gender">
        <el-select v-model="infoForm.gender" placeholder="请选择性别">
          <el-option
            style="fontSize:0.14rem; height:0.34rem; lineHeight:0.34rem;"
            label="男"
            :value="1"
          ></el-option>
          <el-option
            style="fontSize:0.14rem; height:0.34rem; lineHeight:0.34rem;"
            label="女"
            :value="2"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="bottom-btns">
      <el-button type="primary" size="small" :loading="okLoading" @click="!okLoading && _save()">保存</el-button>
    </div>
  </div>
</template>
<script>
import store from "store";
import { mapState } from "vuex";
import { localStorageUtil, validataPhone, debounce } from "utils/util";
import { reqGetInformation, reqUpdateInformation } from "api/personal-center";
export default {
  data() {
    const nicknameR = (rule, val, callback) => {
      if (this.okLoading && !val) {
        return callback(new Error("请输入昵称"));
      } else if (!val) {
        return callback();
      }
      if (val.length > 20 || val.length < 2) {
        return callback(
          new Error("昵称2-20个字符，可由中英文、数字、“_”、“-”组成")
        );
      }
      let reg = /^[\u4e00-\u9fa5a-zA-Z0-9_-]{2,20}$/g;
      if (!reg.test(val)) {
        return callback(
          new Error("昵称2-20个字符，可由中英文、数字、“_”、“-”组成")
        );
      }
      callback();
    };
    const parentNameR = (rule, val, callback) => {
      if (this.okLoading && !val) {
        return callback(new Error("请输入姓名"));
      } else if (!val) {
        return callback();
      }
      if (val.length > 10 || val.length < 2) {
        return callback(new Error("姓名仅支持2-10个字符"));
      }
      let reg = /^[\u4e00-\u9fa5]{2,20}$/g;
      if (false && !reg.test(val)) {
        return callback(new Error("姓名2-20个字符，仅支持中文"));
      }
      callback();
    };
    const genderR = (rule, val, callback) => {
      if (this.okLoading && !val) {
        return callback(new Error("请选择性别"));
      }
      if (!val) {
        return callback();
      }
      callback();
    };
    return {
      okLoading: false,
      infoForm: {
        phone: "",
        nickname: "",
        parentName: "",
        gender: null
      },
      ruleForm: {
        nickname: [{ trigger: "blur", validator: nicknameR }],
        parentName: [{ trigger: "blur", validator: parentNameR }],
        gender: [{ trigger: "change", validator: genderR }]
      }
    };
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  watch: {
    accountInfo: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.infoForm = Object.assign({}, val);
          this.loading = false;
        }
      }
    }
  },
  methods: {
    /**
     * 保存
     * **/
    _save: debounce(function() {
      this.okLoading = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this._updateInfo();
        } else {
          this.okLoading = false;
        }
      });
    }),
    /**
     * 获取基本资料
     * **/
    _getInfo() {
      let obj = {};
      return reqGetInformation(obj)
        .then(res => {
          if (res.code === 0) {
            let _info = res.data;
            this.infoForm = Object.assign({}, _info);
            store.commit("SET_USER", res.data);
          } else {
            this.$message({
              type: "error",
              message: "基本资料获取失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          console.log("获取基本资料失败", err);
        });
    },
    /**
     * 更新基本资料
     * **/
    _updateInfo() {
      let obj = {
        nickname: this.infoForm.nickname,
        parentName: this.infoForm.parentName,
        gender: this.infoForm.gender
      };
      return reqUpdateInformation(obj)
        .then(res => {
          this.okLoading = false;
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: "基本资料已更新",
              duration: 2000
            });
            let _info = store.state.accountInfo;
            store.commit("SET_USER", Object.assign({}, _info, obj));
          } else {
            this.$message({
              type: "error",
              message: "基本资料更新失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          this.okLoading = false;
          console.log("基本资料更新失败", err);
        });
    }
  },
  created() {}
};
</script>
<style lang="less">
.basic-info-wrapper {
  .self-el-form {
    padding-top: 1.2rem;
    padding-left: 2.45rem;
    padding-right: 2.63rem;
    .el-form-item__label {
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
    }
    .el-input__icon {
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
      margin-right: 0.05rem;
    }
    .el-form-item {
      margin-top: 0.3rem;
      .el-form-item__content {
        line-height: 0;
        .el-input {
          width: 3.68rem;
          height: 0.44rem;
          font-size: 0.14rem;
          .el-input__inner {
            height: 0.4rem;
          }
        }
      }
    }
  }
  .el-select .el-input .el-input__suffix {
    height: 0.4rem;
    line-height: 0.4rem;
  }
  .bottom-btns {
    width: 100%;
    height: 0.44rem;

    .el-button {
      width: 3.38rem;
      height: 0.44rem;
      margin-left: 3.61rem;
      margin-right: 2.87rem;
      font-size: 0.16rem;
      line-height: 0.44rem !important;
    }
  }

  .self-el-form {
    padding-bottom: 0;
  }
}
.el-input.is-disabled .el-input__inner {
  background: #c2eda6 !important;
}
.basic-info-wrapper {
}
// @media (max-width: 2560px) {
//   .el-select-dropdown__item {
//     font-size: 0.14rem !important;
//     height: 0.34rem !important;
//     line-height: 0.34rem !important;
//   }
// }
// @media (max-width: 3840px) {
//   .el-select-dropdown__item {
//     font-size: 0.14rem !important;
//     height: 0.34rem !important;
//     line-height: 0.34rem !important;
//   }
// }
</style>